.blog {
  &_subtitle {
    margin-bottom: 30px;
  }
  &_list {}
  &_item {
    display: flex;
    align-items: stretch;

    position: relative;
    background-color: $qntm--color-main-bg-secondary;

    border: 1px solid $qntm--color-main-border;

    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &__sidebar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      width: 150px;
      flex: 0 0 150px;
      background-color: darken($qntm--color-main-bg-secondary, 5%);
      border-right: 1px solid $qntm--color-main-border;
    }
    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      width: 100%;
    }

    &__header {
      padding: 40px;
    }
    &__category {

      &_link {
        font-size: 14px;
        color: $qntm--color-main-text;

        &:focus, &:hover {
          color: $qntm--color-white;
          text-decoration: none;
        }

      }
    }
    &__title {
      display: block;
      width: 100%;
      margin-top: 0;

      &_link {
        color: $qntm--color-white;
        text-shadow: 5px 5px 30px rgba(255, 255, 255, 0.3);

        &:focus, &:hover {
          color: $qntm--color-white;
          text-decoration: none;
          text-shadow: 5px 5px 30px rgba(255, 255, 255, 0.5);
        }
      }
    }
    &__intro {
      color: $qntm--color-main-text;
      line-height: 1.7;
      margin-bottom: 20px;

      h1, h2, h3, h4, h5, h6 {
        color: $qntm--color-white;
        margin-top: 20px;
        margin-bottom: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      a {
        border-bottom: 1px solid;
      }
    }

    &__footer {
      border-top: 1px solid $qntm--color-main-border;
      background-color: darken($qntm--color-main-bg-secondary, 2%);
      padding: 20px 40px;

      &--left {}
      &--right {}
    }

    &__info_block {
      display: flex;

      .blog_item__counters {
        flex: 1 0 auto;
      }

      .blog_item__profile {
        flex: 0 0 100px;
      }
    }

    &__counters {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 50px;

      text-align: center;

      &--horizontal {

        > * {
          display: flex;
          flex-direction: row;
          > i {
            margin-right: 15px;
          }
          > span {
            flex: 1;
            display: flex;
          }
        }
      }

      i, svg {
        display: block;
        width: 100% !important;
        text-align: center;
        font-size: 20px;
        margin-bottom: 3px;
      }

      > * {
        margin-bottom: 10px;
        font-size: 14px;

        color: $qntm--color-white;

        &:focus, &:hover {
          color: $qntm--color-accent;
          text-decoration: none;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__property {
      display: flex;
      width: 100%;
      > i, svg {
        flex: 0 0 30px;
      }
    }
    &__comments_count {}
    &__hits {}
    &__profile {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 20px;

      &_text {
        text-align: center;
      }
      &_image {
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        width: 50px;
        height: 50px;

        border: 4px solid $qntm--color-main-border;
        border-radius: 100%;
        overflow: hidden;

        &:focus, &:hover {
          transform: scale(1.1);
        }

        img {
          object-fit: contain;
          max-width: 100%;
          height: auto;

        }
      }
    }
    &__author {
      font-size: 14px;
      color: $qntm--color-main-text;

      &:focus, &:hover {
        color: $qntm--color-white;
        text-decoration: none;
      }
    }
    &__date {
      font-size: 12px;
      color: $qntm--color-main-text;
    }
  }
  &_tags {

  }
}