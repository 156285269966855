.search {
  &__tool {
    font-size: 24px;

    @include respond-to(mobile) {
      display: none;
    }

    &.hide {
      display: none;
    }
  }
  &__wrapper {
    position: relative;
    &.active {
      .search__holder {
        width: 350px;
        background-color: $qntm--color-dark;
        transition: all .5s cubic-bezier(0.000, 0.105, 0.035, 1.570);
        margin-right: 40px;
        border: 1px solid $qntm--color-border;
        border-radius: 10px;
        @include respond-to(b4-tablet) {
          width: 240px;
          margin-right: 30px;
        }
      }
      .search__input {
        opacity: 1;
        //transform: translate(0, 10px);
      }
      .search__icon {
        background-color: rgba(0,0,0,0);
        color: $qntm--color-white;
      }
      .search__close {
        display: inline-block;
      }

    }
  }
  &__holder {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    width: 50px;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    background-color: rgba(0,0,0,0);
    transition: all 0.3s ease-in-out;
  }
  &__input {
    //width: 100%;
    padding: 10px 40px 10px 20px;
    width: 100%;
    height: 50px;
    opacity: 0;
    position: absolute;
    top:0px;
    left:0px;
    background: transparent;
    box-sizing: border-box;
    border:none;
    outline:none;
    font-size: 16px;
    font-family: 'Gilroy';
    color: $qntm--color-white;
    //transform: translate(0, 60px);
    //transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    //transition-delay: 0.3s;
  }
  &__icon {
    width:50px;
    height:50px;
    border:none;
    border-radius:6px;
    //background: #FFF;
    background-color: rgba(0,0,0,0);
    color: $qntm--color-white;
    padding:0px;
    outline:none;
    position: relative;
    z-index: 2;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  &__close {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    display: none;
  }
}