.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  color: $qntm--color-white;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;

    @include respond-to(wide) {
      margin-top: 30px;
    }
    @include respond-to(mobile) {
      margin-top: 10px;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    margin: 0 -5px;
    &_link {
      padding: 0 5px;
    }
  }

  &__tools {
    display: flex;
    align-items: center;
    margin: 0 -11px;

    @include respond-to(b4-small-tablet) {
      margin: 0 -5px;
    }
  }
  &__tool {
    display: flex;
    padding: 0 11px;

    @include respond-to(b4-small-tablet) {
      padding: 0 5px;
    }
  }
}