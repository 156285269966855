.contact {
  &__wrapper {}
  &__email {
    font-size: 42px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 25px;

    @include respond-to(b4-tablet) {
      font-size: 28px;
      margin-bottom: 15px;
    }

    a {
      color: $qntm--color-white;
      text-decoration: none;
    }
  }
  &__address {
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 50px;

    @include respond-to(b4-tablet) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
  &__more {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;

    @include respond-to(b4-tablet) {
      font-size: 14px;
    }

  }
  &__map {
    &_gradient {
      position: absolute;
      pointer-events: none;
      width: 100%;
      background: linear-gradient(0deg,$qntm--color-dark,rgba(70,31,65,0));
      bottom: 0;
      height: 50vh;
      left: 0;
      z-index: 150;

      @include respond-to(b4-tablet) {
        height: 80%;
      }
    }
  }
}