ul.onepage-pagination {
  top: 50%;
  transform: translateY(-50%);
  //right: auto;
  //left: 50%;
  //@include transform(translateX(610px));

  //@include respond-to(middle-tablet) {
  //  @include transform(translateX(500px));
  //}
  //@include respond-to(b4-tablet) {
  //  @include transform(translateX(380px));
  //}
  //@include respond-to(b4-small-tablet) {
  //  @include transform(translateX(290px));
  //}
  //@include respond-to(b4-mobile) {
  //  display: none;
  //}

  @include respond-to(b4-tablet) {
    display: none;
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a {
      position: relative;
      padding: 0;
      width: auto;
      height: auto;
      display: inline-block;
      color: $qntm--color-white;
      text-decoration: none;
      transition: .3s;

      &:before {
        content: '';
        position: absolute;
        display: inline-block;
        background-color: $qntm--color-accent;
        width: 0;
        height: 2px;
        border: 0;
        margin-top: 0;
        right: 15px;
        left: auto;

        top: 50%;
        transform: translateY(-50%);

        transition: .3s;
      }
      &:after {
        content: "0"attr(data-index);
        position: relative;
        font-size: 20px;
        font-weight: 500;

        //color: $color-border;
      }


      &.active {
        &:before {
          width: 40px;
        }

        &:after {
          font-size: 34px;
        }
      }


    }
  }
}