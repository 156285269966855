.about_work {
  &__wrapper {
    margin-top: -160px;

    @include respond-to(b4-tablet) {
      margin-top: 0;
    }
  }
  &__list {
    display: flex;
    position: relative;
    margin-top: 60px;
    @include respond-to(middle-tablet) {
      padding-left: 250px;
    }
    @include respond-to(b4-tablet) {
      padding-left: 0;
      margin-top: 30px;
    }
  }
  &__tabs {
    flex: 0 0 360px;

    @include respond-to(b4-tablet) {
      position: relative;
      text-align: center;
    }
    &_item {
      position: relative;
      color: $qntm--color-gray-primary;
      font-size: 24px;
      padding: 15px 20px 15px 65px;
      line-height: 1;
      cursor: pointer;
      transition: .3s;

      @include respond-to(middle-tablet) {
        padding: 10px 10px 10px 30px;
        font-size: 20px;
      }
      @include respond-to(b4-tablet) {
        display: inline-block;
        padding: 10px;
        font-size: 18px;
      }

      &:after {
        @include respond-to(b4-tablet) {
          content: '';
          position: absolute;
          top: 100%;
          transform: none;
          right: 50%;
          background-color: $qntm--color-white;

          display: inline-block;
          width: 0;
          height: 2px;

          transition: .3s;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background-color: $qntm--color-white;

        display: inline-block;
        width: 0;
        height: 2px;

        transition: .3s;

        @include respond-to(b4-tablet) {
          top: 100%;
          transform: none;
          left: 50%;
        }
      }

      &:hover, &:focus {
        color: $qntm--color-white;
      }


      &.active {
        color: $qntm--color-white;
        text-shadow: 5px 1px 30px rgba(255, 255, 255, 0.3);

        &:after {
          @include respond-to(b4-tablet) {
            width: 50%;
          }
        }
        &:before {
          width: 40px;
          @include respond-to(middle-tablet) {
            width: 15px;
          }
          @include respond-to(b4-tablet) {
            width: 50%;
          }
        }
      }
    }
  }
  &__content {
    &_item {
      display: none;

      color: $qntm--color-gray-primary;
      font-size: 24px;

      line-height: 1.25;
      padding: 15px 0;

      @include respond-to(middle-tablet) {
        font-size: 18px;
      }

      &.active {
        display: block;
      }
      p {
        @include respond-to(middle-tablet) {
          font-size: 18px;
        }
        @include respond-to(b4-tablet) {
          font-size: 16px;
        }
        @include respond-to(b4-mobile) {
          font-size: 14px;
        }
      }
    }
  }
  &__title {
    margin-top: 0;
    line-height: normal;
    margin-bottom: 40px;
    color: $qntm--color-white;
    font-size: 30px;
    font-weight: 600;
    text-shadow: 5px 1px 30px rgba(255, 255, 255, 0.3);

    @include respond-to(middle-tablet) {
      margin-bottom: 20px;
      font-size: 24px;
    }
    @include respond-to(b4-tablet) {
      font-size: 20px;
    }
  }
}