pre code {
  padding: 1em !important;
  //background: #fbfbfb !important;
  //border: 1px solid #f9f9f9;
  overflow-y: auto;
  margin: 1.5em 0;
  border: 1px solid $qntm--color-main-border;
  background-color: darken($qntm--color-main-bg-secondary, 2%) !important;
  font-size: 0.8em;
  font-family: monospace;
}
pre.hljs-code {


  word-wrap: normal;
  position: relative;
  clear: left;
  overflow: visible;





}
pre.hljs-code:before {
  content: attr(data-lines);
  position: absolute;
  top: 1em;
  line-height: 1.7em;
  text-align: right;
  left: -40px;
  font-size: 0.8em;
  font-family: monospace;
  width: 30px;
  opacity: 0;
  color: $qntm--color-main-text;
  transition: opacity 0.4s;
}
pre.hljs-code:hover:before {
  opacity:1;
}
.hljs-line-numbers {
  text-align: right;
  border-right: 1px solid #ccc;
  color: #999;
  user-select: none;
}