.comments {
  &__wrapper {
    position: relative;
  }
  &__block {
    position: relative;
    margin-top: 30px;
    padding: 40px;

    background-color: $qntm--color-main-bg-secondary;
    border: 1px solid $qntm--color-main-border;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }


  }

  &__list {
    padding-left: 25px;

    .ticket-comment-dot {
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAFCAYAAACw/UeDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjBEOUVGOUM5QzYxQzExRThCRjc3OTE0REZEOEQ0NTM3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjBEOUVGOUNBQzYxQzExRThCRjc3OTE0REZEOEQ0NTM3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MEQ5RUY5QzdDNjFDMTFFOEJGNzc5MTRERkQ4RDQ1MzciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MEQ5RUY5QzhDNjFDMTFFOEJGNzc5MTRERkQ4RDQ1MzciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz41555wAAAAO0lEQVR42mL4//8/Awi7urr+h7GpjZkYgMDNze0/Mk1twAjyAbrgrl27GKlpCRO6gdS2AAzoEScAAQYAXfefD+rX+OYAAAAASUVORK5CYII=');
    }
    .ticket-comment-header {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .ticket-comment-text {
      margin-top: 5px;
      font-size: 14px;
    }
    .ticket-comment-text {
      font-size: 14px;
      background-color: darken($qntm--color-main-bg-secondary, 5%);
      border: 1px solid $qntm--color-main-border;
      padding: 7px 20px
    }

    .reply {
      font-size: 14px;
      color: #8c8c8c;

      &:hover {
        color: $qntm--color-white;
      }
    }

    .reply-form {
      margin-top: 20px;

      .markItUpHeader ul {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        a {
          background-image: url('../img/editor_dark.png');
        }
      }
    }
    .error {
      color: #f30000;
      font-size: 14px;
      font-weight: 600;
    }
  }
  &__inner {
    overflow: hidden;
  }
  &__form {
    .markItUpHeader ul {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      a {
        background-image: url('../img/editor_dark.png');
      }
    }
  }
  &__title {
    margin-top: 0;
  }
}