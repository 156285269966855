.btn {
  background-color: #353c42;
  color: #fff;
  transition: .3s;
  cursor: pointer;

  &:hover, &:focus {
    background-color: darken(#353c42, 5%);
    outline: none;
  }
}