#googleMap {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
}

/*style the box*/
.gm-style .gm-style-iw {
  background-color: $qntm--color-bg-gray !important;
  color: $qntm--color-gray-secondary !important;

  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;

  padding: 20px;
  display: block !important;
}


/*style the arrow*/
.gm-style div div div div div div div div {
  color: $qntm--color-gray-secondary;
  font-size: 16px;
  font-family: 'Gilroy';
}

/*style the link*/
.gm-style div div div div div div div div a {
  color: #f1f1f1;
  font-weight: bold;
}
.gm-style > div:first-child > div:last-child > div:last-child > div:last-child > div {
  //height: 100% !important;
  > div:first-child {
    width: 100%;
    height: 100%;
    > div {
      &:first-child {
        width: 0 !important;
        height: 0 !important;
        border-style: solid !important;
        border-width: 20px 20px 0 20px !important;
        border-color: $qntm--color-bg-gray transparent transparent transparent !important;
        top: 100% !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
      &:nth-child(2) {
        box-shadow: none;
      }
      &:nth-child(3) {
        display: none !important;
      }
    }
  }
}