.service {
  &__wrapper {
    h2 {
      margin-bottom: 40px;

      @include respond-to(b4-tablet) {
        margin-bottom: 15px;
      }
    }
  }
  &__caption {
    margin-bottom: 30px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    width: 50%;

    @include respond-to(b4-tablet) {
      width: 100%;
    }

    &:hover {
      .service__inner {
        &:before, &:after {
          animation: motion1 0.8s forwards;
        }

        .svc {
          &:before, &:after {
            animation: motion2 0.8s forwards;
          }
        }
      }
      .service__desc {
        color: $qntm--color-white;
        text-shadow: 2px 2px 17px rgba(255, 255, 255, .5);
      }
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
    position: relative;
    //background-color: $qntm--color-bg-gray;
    padding: 25px 30px;
    height: 230px;

    text-decoration: none;

    color: $qntm--color-white;

    border: 1px solid #7a7a8f;

    &:hover, &:focus {
      text-decoration: none;
      color: $qntm--color-white;
    }

    * {
      z-index: 1;
    }

    &:before,
    &:after {
      content: "";
      width: 0;
      height: 1px;
      position: absolute;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      background: #877D9B;
      z-index: 2;
    }

    &:before {
      right: 0;
      top: 0;
    }
    &:after {
      left: 0;
      bottom: 0;
    }

    .svc {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;

      &:before,
      &:after {
        content: "";
        width: 1px;
        height: 0;
        position: absolute;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        background: #877D9B;
        z-index: 2;
      }

      &:before {
        left: 0;
        bottom: 0;
      }

      &:after {
        right: 0;
        top: 0;
      }
    }
  }
  &__title {
    font-size: 38px;
    font-weight: 700;
    line-height: 1;

    border-bottom: 1px solid #7a7a8f;
    padding-bottom: 20px;
    margin-top: 0;
    margin-bottom: 20px;

    text-shadow: 2px 2px 25px rgba(255, 255, 255, .3);

    @include respond-to(b4-tablet) {
      font-size: 26px;
      margin-bottom: 15px;
    }
  }
  &__desc {
    color: $qntm--color-gray-secondary;
    font-size: 16px;

    transition: .3s;

    @include respond-to(b4-tablet) {
      font-size: 14px;
    }
  }
}

@keyframes motion1 {
  0% {
    width: 0;
  }

  50% {
    width: 100%;
    background: #ffffff;
  }

  100% {
    opacity: 0;
  }
}

@keyframes motion2 {
  0% {
    height: 0;
  }

  50% {
    height: 100%;
    background: #ffffff;
  }

  100% {
    opacity: 0;
  }
}