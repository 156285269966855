

// colors
$qntm--color-dark:                          #231f20 !default;
$qntm--color-white:                         #ffffff !default;
$qntm--color-accent:                        #27aae2 !default;
$qntm--color-dark-gray:                     #737373 !default;
$qntm--color-gray-primary:                  #b8b8b9 !default;
$qntm--color-bg-gray:                       #2b2b2b !default;
$qntm--color-gray-secondary:                #6c6c6c !default;
$qntm--color-border:                        #464646 !default;
$qntm--color-bg-dark-gray:                  #292929 !default;
$qntm--color-main-bg:                       #171c20 !default;
$qntm--color-main-bg-secondary:             #22272B !default;
$qntm--color-main-border:                   #393d41 !default;
$qntm--color-main-text:                     #a6a6a6 !default;


// main-slider
$qntm--sidebar-width-sm:                    120px !default;
$qntm--sidebar-width-xl:                    320px !default;

$qntm--numberbar-width-sm:                  50px !default;
$qntm--numberbar-width-xl:                  80px !default;

// sizes
// Small devices (landscape phones, 576px and up)
//@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
//@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
//@media (min-width: 992px) { ... }

// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }

$qntm--wide-container-width-xl:             1680px !default;
$qntm--wide-container-width-lg:             1180px !default;
$qntm--wide-container-width-md:             960px !default;
$qntm--wide-container-width-sm:             740px !default;


// z-index

// text

// quote
$qntm--quote-border-radius:                 4px !default;


$qntm--color-twitter:                       #00aced !default;
$qntm--color-vk:                            #4c75a3 !default;
$qntm--color-pinterest:                     #bd081c !default;