.sidebar {
  &_block {
    border: 1px solid $qntm--color-main-border;
    background-color: darken($qntm--color-main-bg-secondary, 2%);

    margin-bottom: 20px;

    &__title {
      padding: 10px 20px;
      background-color: $qntm--color-main-bg-secondary;
      border-bottom: 1px solid $qntm--color-main-border;
    }
    &__body {
      padding: 10px 20px;
    }
  }
}