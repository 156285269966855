.popular {
  &__list {}
  &__item {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: $qntm--color-white;
    padding: 5px 15px;
    margin: 0 -15px;
    font-size: 14px;

    border-radius: 4px;
    
    background-image: url("../img/hr-dot-gray.gif");
    background-repeat: repeat-x;
    background-position: 50% 70%;

    transition: transform .3s;

    &:hover, &:focus {
      text-decoration: none;
      color: $qntm--color-white;

      transform: scale(1.05);
      background-color: $qntm--color-main-bg-secondary;

      box-shadow: 0 0 20px rgba(0,0,0,0.1);

      .popular__title,
      .popular__counter {
        background-color: $qntm--color-main-bg-secondary;
      }

      .popular__title {
        color: $qntm--color-white;
      }

    }
  }
  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 90%;

    color: $qntm--color-main-text;

    padding: 0 15px;
    margin: 0 -15px;

    background-color: darken($qntm--color-main-bg-secondary, 2%);

    transition: color .3s;
  }
  &__counter {
    color: $qntm--color-main-text;

    padding: 0 15px;
    margin: 0 -15px;

    background-color: darken($qntm--color-main-bg-secondary, 2%);

    transition: none;
  }
}