.treeview {
  &_node {
    margin-left: 18px;
    zoom: 1;

    background-image: url("../img/i.gif");
    background-position: top left;
    background-repeat: repeat-y;

    &--expand {
      &_leaf {
        > .treeview_node__expand {
          background-image: url("../img/expand_leaf.gif");
          cursor: auto;
        }
        
        > .treeview_node__content {
          background-image: url("../img/document.gif");
        }
      }
      &_open {
        > .treeview_node__expand {
          background-image: url("../img/expand_minus.gif");
          cursor: pointer;
        }
        > .treeview_node__container {
          display: block;
        }
        > .treeview_node__content {
          background-image: url("../img/open.gif");
        }
      }
      &_closed {
        > .treeview_node__expand {
          background-image: url("../img/expand_plus.gif");
          cursor: pointer;
        }
        > .treeview_node__container {
          display: none;
        }
        > .treeview_node__content {
          background-image: url("../img/closed.gif");
        }
      }
    }

    &--root {
      margin-left: 0;
    }

    &--last {
      background-image: url("../img/i_half.gif");
      background-repeat: no-repeat;
    }


    &__container {}
    &__expand {
      width: 18px;
      height: 18px;
      float: left;
    }
    &__content {
      min-height: 18px;
      font-size: 14px;
      margin-left: 18px;
      padding-left: 26px;

      background-repeat: no-repeat;
      background-position: 0 0;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

}