.typewrite {
  &__wrap {
    position: relative;

    &:after {
      content: '';

      display: inline-block;
      width: 32px;
      height: 10px;
      margin-left: 7px;
      background-color: $qntm--color-white;

      @include respond-to(b4-tablet) {
        width: 20px;
        height: 4px;
      }
    }
  }
}