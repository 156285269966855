table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  border: 1px solid $qntm--color-main-border;

  th {
    padding: 30px 15px;
    font-weight: 700;
    color: #fff;
    background: $qntm--color-main-bg;
    border-right: 1px solid hsla(0,0%,94%,.1);
    border-top: 1px solid $qntm--color-main-border;
    border-bottom: 1px solid $qntm--color-main-border;
  }

  thead {
    tr {
      th {
        border-top: 0;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 20px 15px;
        color: $qntm--color-main-text;
        //background: #fff;
        border-right: 1px solid $qntm--color-main-border;
        border-left: 1px solid $qntm--color-main-border;
      }

      &:first-child {
        td {
          //border-top: 1px solid $qntm--color-main-border;
        }
      }
      &:nth-child(2n) {
        td {
          background-color: darken($qntm--color-main-bg-secondary, 4%);
        }
      }
    }
  }
}