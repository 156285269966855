.quote {
  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include respond-to(b4-tablet) {
      margin-bottom: 40px;
    }

  }
  &__text {
    background-color: rgba(0,0,0,0.4);
    padding: 26px 22px;
    color: $qntm--color-dark-gray;
    font-size: 18px;
    line-height: 1.3;
    max-width: 560px;
    margin: 20px 0;
    position: relative;
    text-indent: 40px;
    border-radius: $qntm--quote-border-radius;

    @include respond-to(middle-tablet) {
      font-size: 16px;
    }
    @include respond-to(b4-mobile) {
      font-size: 14px;
    }

    &:before {
      content: '“';
      font-size: 60px;
      color: $qntm--color-white;
      position: absolute;
      top: 26px;
      left: 22px;
      line-height: 43px;
      height: 18px;
      width: 25px;
      text-indent: 0;
    }
  }
  &__author {
    font-size: 24px;
    @include respond-to(middle-tablet) {
      font-size: 20px;
    }
  }
}