input {
  &[type=text] {
    position: relative;
    display: block;

    padding: 7px 20px;
    font-size: 14px;

    background-color: rgba(0,0,0,0);
    border: 1px solid $qntm--color-main-border;

    width: 100%;
    color: $qntm--color-main-text;

    transition: .3s;

    border-radius: 0;

    &:hover, &:focus {
      color: $qntm--color-white;
      border-color: $qntm--color-white;
      background-color: rgba(0,0,0,0);
    }
  }
}