.social {
  &__list {
    display: flex;
    margin: 0 15px;
  }
  &__item {
    padding: 0 15px;

  }
  &__link {
    font-size: 24px;
    color: $qntm--color-white;
    text-decoration: none;

    &:focus, &:hover {
      text-decoration: none;
    }

    &--twitter {
      &:focus, &:hover {
        color: $qntm--color-twitter;
      }
    }
    &--vk {
      &:focus, &:hover {
        color: $qntm--color-vk;
      }
    }
    &--pinterest {
      &:focus, &:hover {
        color: $qntm--color-pinterest;
      }
    }
  }
}