.main-slider {


  
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;

    //background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    overflow: hidden;

    @include respond-to(b4-tablet) {
      background-image: none !important;
      margin-bottom: 40px;
      margin-top: 20px;
    }

    .wide-container {
      height: 100%;
    }

    &.mapContainer {
      @include respond-to(b4-tablet) {
        min-height: 500px;
      }
      .wide-container {
        pointer-events: none;
      }
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-left: $qntm--sidebar-width-xl;
    padding-right: $qntm--numberbar-width-xl;

    height: 100%;

    @include respond-to(wide) {
      padding-left: 220px;
    }

    @include respond-to(b4-tablet) {
      padding-left: 0;
      padding-right: 0;
    }


    p {
      font-size: 24px;
      @include respond-to(middle-tablet) {
        font-size: 20px;
        line-height: 1.5;
      }
      @include respond-to(b4-tablet) {
        margin-bottom: 20px;
      }
      @include respond-to(b4-mobile) {
        font-size: 14px;
      }
    }
  }
  &__title {
    font-size: 42px;
    font-weight: 700;
    margin: 0;
    line-height: normal;
    text-shadow: 5px 5px 30px rgba(255, 255, 255, .3);

    @include respond-to(middle-tablet) {
      font-size: 32px;
      line-height: 1;
    }
    @include respond-to(b4-tablet) {
      font-size: 26px;
      line-height: 1;
    }

    &--main {
      font-size: 60px;
      text-transform: uppercase;
      @include respond-to(middle-tablet) {
        font-size: 40px;
      }
      @include respond-to(b4-tablet) {
        font-size: 25px;
        margin-bottom: 20px;
      }
    }
  }
}