.tags {
  &_list {

  }
  &_item {
    display: inline-block;
    padding: 5px 15px;

    background-color: $qntm--color-main-bg-secondary;
    color: $qntm--color-main-text;

    font-size: 14px;

    border-radius: 50px;

    margin-right: 4px;
    margin-bottom: 4px;

    &:last-child {
      margin-right: 0;
    }

    &:focus, &:hover {
      text-decoration: none;
      background-color: $qntm--color-accent;
      color: $qntm--color-white;
    }
  }
}