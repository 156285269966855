.main-page {
  height: 100%;
  width: 100%;

  background-color: $qntm--color-dark;
  background-image: url("../img/main/background_texture.jpg");
  color: $qntm--color-white;

  @include respond-to(b4-tablet) {
    height: auto;
    padding-top: 100px;
  }

  &__slider-navigation {
    position: absolute;
    z-index: 5;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}