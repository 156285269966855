//@include font-face(AFuturicaExtraBlack, fonts/AFuturicaExtraBlack, 500, normal, eot woff woff2 ttf);

//Значение	Общее название
//100	Тонкий (Волосяной) Thin (Hairline)
//200	Дополнительный светлый (Сверхсветлый) Extra Light (Ultra Light)
//300	Светлый Light
//400	Нормальный Normal
//500	Средний Medium
//600	Полужирный Semi Bold (Demi Bold)
//700	Жирный Bold
//800	Дополнительный жирный (Сверхжирный) Extra Bold (Ultra Bold)
//900	Черный (Густой) Black (Heavy)

@include font-face(Gilroy, fonts/Gilroy-Thin, 100, normal, eot svg woff woff2 ttf);
@include font-face(Gilroy, fonts/Gilroy-ThinItalic, 100, italic, eot svg woff woff2 ttf);

@include font-face(Gilroy, fonts/Gilroy-UltraLight, 200, normal, eot svg woff woff2 ttf);
@include font-face(Gilroy, fonts/Gilroy-UltraLightItalic, 200, italic, eot svg woff woff2 ttf);

@include font-face(Gilroy, fonts/Gilroy-Light, 300, normal, eot svg woff woff2 ttf);
@include font-face(Gilroy, fonts/Gilroy-LightItalic, 300, italic, eot svg woff woff2 ttf);

@include font-face(Gilroy, fonts/Gilroy-Regular, 400, normal, eot svg woff woff2 ttf);
@include font-face(Gilroy, fonts/Gilroy-RegularItalic, 400, italic, eot svg woff woff2 ttf);

@include font-face(Gilroy, fonts/Gilroy-Medium, 500, normal, eot svg woff woff2 ttf);
@include font-face(Gilroy, fonts/Gilroy-MediumItalic, 500, italic, eot svg woff woff2 ttf);

@include font-face(Gilroy, fonts/Gilroy-SemiBold, 600, normal, eot svg woff woff2 ttf);
@include font-face(Gilroy, fonts/Gilroy-SemiBoldItalic, 600, italic, eot svg woff woff2 ttf);

@include font-face(Gilroy, fonts/Gilroy-Bold, 700, normal, eot svg woff woff2 ttf);
@include font-face(Gilroy, fonts/Gilroy-BoldItalic, 700, italic, eot svg woff woff2 ttf);

@include font-face(Gilroy, fonts/Gilroy-ExtraBold, 800, normal, eot svg woff woff2 ttf);
@include font-face(Gilroy, fonts/Gilroy-ExtraBoldItalic, 800, italic, eot svg woff woff2 ttf);

@include font-face(Gilroy, fonts/Gilroy-Heavy, 900, normal, eot svg woff woff2 ttf);
@include font-face(Gilroy, fonts/Gilroy-HeavyItalic, 900, italic, eot svg woff woff2 ttf);