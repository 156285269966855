.portfolio_slider {
  &__list {
    height: 100%;
    overflow: visible;


    .slick-list {
      height: 100% !important;
      overflow: visible;
    }
    .slick-slide {
      opacity: .3;
      height: 300px !important;
      margin: 10px;
      transition: .3s;

      @include respond-to(b4-tablet) {
        height: auto !important;
      }


      &.slick-center {
        opacity: 1;
        .portfolio_slider__info {
          opacity: 1;

        }
      }

      .portfolio_slider__info {
        opacity: 0;

        transition: .3s;
      }
    }
    .slick-prev {
      transform: rotate(90deg) translateX(-20px);
    }
    .slick-next {
      left: -25px;
      right: auto;
      transform: rotate(90deg) translateX(20px);
    }
  }
  &__item {
    display: flex !important;
    align-items: center;
    @include respond-to(b4-tablet) {
      flex-direction: column;
    }
  }
  &__preview {
    display: flex;
    align-items: center;
    justify-content: center;

    line-height: 0;
    max-width: 560px;
    width: 100%;

    max-height: 300px;
    overflow: hidden;

    @include respond-to(b4-tablet) {
      max-width: 300px;
      margin-bottom: 20px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
  &__info {
    margin-left: 50px;
    @include respond-to(b4-tablet) {
      margin-left: 0;
    }
  }

  &__type {
    font-size: 16px;
    margin-bottom: 8px;

    a {
      color: $qntm--color-white;

      &:focus, &:hover {
        color: $qntm--color-accent;
        text-decoration: none;
      }
    }

    @include respond-to(b4-tablet) {
      font-size: 14px;
    }
  }
  &__project {
    font-size: 34px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 11px;

    a {
      color: $qntm--color-white;

      &:focus, &:hover {
        color: $qntm--color-accent;
        text-decoration: none;
      }
    }

    @include respond-to(b4-tablet) {
      font-size: 26px;
    }
  }
  &__tags {
    margin-bottom: 20px;

    a {
      color: $qntm--color-white;

      &:focus, &:hover {
        color: $qntm--color-accent;
        text-decoration: none;
      }
    }

    @include respond-to(b4-tablet) {
      font-size: 14px;
    }
  }
  &__link {
    a {
      color: $qntm--color-white;
      border-bottom: 1px solid $qntm--color-white;

      &:hover, &:focus {
        text-decoration: none;
        border-color: $qntm--color-accent;
      }
    }
    margin-bottom: 14px;

    @include respond-to(b4-tablet) {
      font-size: 14px;
    }
  }
  &__more {
    @include respond-to(b4-tablet) {
      font-size: 14px;
    }
  }
}