.content {
  &_block {
    background-color: $qntm--color-main-bg-secondary;
    padding: 40px;

    &--bordered {
      border: 1px solid $qntm--color-main-border;
    }

    &--shadow {
      box-shadow: 0 0 20px rgba(0,0,0,0.3);
    }
  }
}