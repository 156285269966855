.footer {
  color: $qntm--color-white;
  width: 100%;

  flex: 0 0 auto;

  &.main_scroll {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    color: $qntm--color-white;
  }

  @include respond-to(b4-tablet) {
    position: relative;
    background-color: $qntm--color-dark;

    padding: 20px 0;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;

    @include respond-to(b4-tablet) {
      margin-bottom: 0;
    }
  }
  &__bunny {
    line-height: 1;
  }
}