.navigation {
  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $qntm--color-main-bg;
    color: $qntm--color-white;
    z-index: 6;
    overflow: hidden;
    height: 0;
    &.active {
      height: 100%;
      overflow: auto;
    }

  }
  &__tool {

  }
  &__block {
    margin-bottom: 50px;
    @include respond-to(mobile) {
      margin-bottom: 20px;
    }
  }

  &__title {
    font-size: 30px;
    line-height: 1;
    padding-bottom: 30px;
    border-bottom: 1px solid $qntm--color-white;
    margin-bottom: 15px;

    @include respond-to(b4-tablet) {
      font-size: 24px;
      padding-bottom: 15px;
      margin-bottom: 10px;
    }
    @include respond-to(mobile) {
      font-size: 20px;
      padding-bottom: 10px;
    }
  }
  &__list {
    &--two-cols {
      columns: 2;
      @include respond-to(mobile) {
        columns: 1
      }
    }
  }
  &__item {}
  &__link {
    display: block;
    position: relative;
    margin: 3px 0;
    padding: 0 35px 0 0;
    color: $qntm--color-white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 24px;
    &:after {
      content: '\f054';

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;

      font-family: 'Font Awesome 5 Free Solid';
      font-size: 16px;
      opacity: 0;

      transition: .3s;
      color: $qntm--color-dark;

    }

    &:hover, &:focus {
      background-color: $qntm--color-white;
      color: $qntm--color-dark;
      text-decoration: none;
      padding-left: 15px;
      &:after {
        opacity: 1;
      }
    }

    @include respond-to(b4-tablet) {
      font-size: 18px;
    }
    @include respond-to(mobile) {
      font-size: 16px;
    }
  }
  &__social_icon {
    display: inline-block;
    width: 40px;
  }
}