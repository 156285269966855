html,
body {
  min-width: 320px;
  height: 100%;
}


body {
  display: flex;
  flex-direction: column;

  font-family: 'Gilroy';
  font-weight: 500;
  font-size: 16px;
  line-height: 1.7;

  background-color: $qntm--color-main-bg;
  background-image: url("../img/main/background_texture.jpg");
  color: $qntm--color-white;
}
::selection {
  background-color: $qntm--color-bg-gray;
  color: $qntm--color-white;
}
* {
  &:focus {
    outline: none !important;
  }
}

h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 19px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 13px;
}
h6 {
  font-size: 11px;
}

h2, h3 {
  margin: 70px 0 30px;
  line-height: 1.2;
  text-transform: none;
  font-weight: 600;
}

b, strong {
  font-weight: 600;
}

img {
  max-width: 100%;
  height: auto; 
}