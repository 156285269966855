
/*
  Миксин для адаптива
*/
@mixin respond-to($media) {
  @if $media == wide {
    @media only screen and (max-width: 1600px) { @content }
  }
  @else if $media == big-tablet {
    @media only screen and (max-width: 1400px) { @content }
  }
  @else if $media == middle-tablet {
    @media only screen and (max-width: 1200px) { @content }
  }
  @else if $media == tablet {
    @media only screen and (max-width: 1024px) { @content }
  }
  @else if $media == b4-tablet {
    @media only screen and (max-width: 992px) { @content }
  }
  @else if $media == b4-small-tablet {
    @media only screen and (max-width: 768px) { @content }
  }
  @else if $media == b4-mobile {
    @media only screen and (max-width: 576px) { @content }
  }
  @else if $media == small-tablet {
    @media only screen and (max-width: 800px) { @content }
  }
  @else if $media == mobile {
    @media only screen and (max-width: 639px) { @content }
  }
  @else if $media == small-mobile {
    @media only screen and (max-width: 400px) { @content }
  }
}

//@mixin respond-to($size) {
//  @media only screen and (max-width: $size + px) { @content; }
//}