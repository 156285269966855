.portfolio {
  &__tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -5px;

    a {
      position: relative;
      display: inline-block;
      padding: 10px 20px;
      margin: 4px 5px;
      color: $qntm--color-gray-secondary;

      z-index: 2;

      &:hover, &:focus, &.active {
        color: $qntm--color-white;
        text-shadow: 2px 2px 17px rgba(255, 255, 255, .5);
        text-decoration: none;
      }
    }

    &--active {
      position: absolute;

      //border: 2px solid $qntm--color-accent;
      border: 1px solid #7a7a8f;
      border-radius: 40px;


      transition: .2s;

      z-index: 1;
    }
  }
  &__container {
    .hidden {
      display: none;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 20px -15px;

  }
  &__item {
    flex: 0 0 33.33%;
    width: 33.33%;
    padding: 15px;


  }
  &__link {
    display: block;
    position: relative;
    background-color: rgba(0,0,0,0.8);
    overflow: hidden;

    *, &:before, &:after {
      box-sizing: border-box;
      transition: all 0.4s ease;
    }

    &:before,
    &:after {
      position: absolute;
      top: 20px;
      right: 20px;
      content: '';
      background-color: $qntm--color-white;
      z-index: 1;
      opacity: 0;
    }

    &:before {
      width: 0;
      height: 1px;
    }
    &:after {
      height: 0;
      width: 1px;
    }

    img {
      backface-visibility: hidden;
      vertical-align: top;
      max-width: 100%;
      height: auto;
      transition: .3s cubic-bezier(0.250, 0.460, 0.450, 0.940);

    }
    &:hover, &:focus {
      img {
        transform: scale(1.1);
        opacity: 0.2;
      }

      &:before, &:after {
        opacity: 1;
        transition-delay: 0.25s;
      }

      &:before {
        width: 40px;
      }

      &:after {
        height: 40px;
      }

      .portfolio__title {
        opacity: 1;
        transition-delay: 0.3s;
      }
    }
  }
  &__title {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 15px 20px;
    margin: 0;
    opacity: 0;
  }
}