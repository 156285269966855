.pagination {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;


  &__navigation {
    margin-bottom: 15px;
  }
  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    position: relative;
    font-size: 11px;
    line-height: 16px;

    &--left {
      margin-right: 20px;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;

        height: 2px;
        width: 10px;
        background-color: $qntm--color-accent;

      }
    }

    &--right {
      margin-left: 20px;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 100%;

        height: 2px;
        width: 10px;
        background-color: $qntm--color-accent;

      }
    }

  }
  &__to {
    position: relative;
    display: inline-block;
    line-height: 34px;
    margin-right: 15px;

    color: $qntm--color-accent;

    &:focus, &:hover {
      text-decoration: none;
      color: $qntm--color-accent;

      span {
        text-shadow: 0px 0px 14px rgb(39, 170, 226);
      }
    }
    span {
      font-size: 14px;
      transition: .3s;
    }

    i, svg {
      position: relative;
      vertical-align: middle;
      font-size: 11px;
      line-height: 16px;
    }



    &--disabled {
      color: $qntm--color-main-text !important;
      cursor: default;
      pointer-events: none;

      span {
        text-shadow: none !important;
      }

      .pagination__icon {
        &:before, &:after {
          background-color: $qntm--color-main-text !important;
        }
      }

    }
  }

  &__pages {}
  &__page {
    display: inline-block;

    color: $qntm--color-main-text;
    background-color: $qntm--color-main-bg-secondary;

    border-radius: 2px;

    line-height: 34px;
    text-align: center;

    width: 40px;
    height: 35px;
    overflow: hidden;

    font-size: 13px;

    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
    &:focus, &:hover {
      color: $qntm--color-white;
      text-decoration: none;
      background-color: $qntm--color-accent;
    }

    &--current {
      color: $qntm--color-white !important;
      background-color: $qntm--color-accent !important;
      pointer-events: none;
    }
    &--more {
      font-size: 16px;
      opacity: .3;

      &:focus, &:hover {
        opacity: 1;
      }
    }
  }
}