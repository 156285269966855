ul.onepage-name-pagination {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  list-style: none;
  z-index: 5;
  margin: 0;
  padding: 0;

  @include respond-to(b4-tablet) {
    display: none;
  }

  li {
    a {
      position: relative;
      color: $qntm--color-white;
      text-decoration: none;
      font-size: 24px;
      text-transform: uppercase;

      &:hover, &:focus {
        color: $qntm--color-accent;
      }

      &.active {
        color: $qntm--color-accent;
        padding-left: 30px;
        text-shadow: 3px 1px 25px rgba(5, 163, 255, 0.84);
        &:before {
          content: '';
          display: inline-block;

          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);

          width: 6px;
          height: 6px;
          background-color: $qntm--color-accent;

          border-radius: 100%;
        }
      }
    }
  }
}