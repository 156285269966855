.wide-container {
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 100%;

  @include media-breakpoint-up(sm) {
    width: $qntm--wide-container-width-sm;

  }
  @include media-breakpoint-up(md) {
    width: $qntm--wide-container-width-md;
  }
  @include media-breakpoint-up(lg) {
    width: $qntm--wide-container-width-lg;
  }

  @include media-breakpoint-up(xl) {
    width: $qntm--wide-container-width-xl;
  }




}