$color-arrow: $qntm--color-gray-primary;
$color-arrow-hover: $qntm--color-white;

$text-arrow-space: 16px;
$shaft-width: 50px;
$shaft-thickness: 1px;
$arrow-head-width: 8px;
$arrow-head-thickness: $shaft-thickness;

.animated-arrow {
  display: inline-block;
  color: $color-arrow;
  text-decoration: none;
  position: relative;
  transition: all 0.2s;

  &__arrow {
    position: relative;
    width: $shaft-width;
    transition: .3s;

    &--left {
      position: absolute;
      top: 50%;
      left: 0;

      .animated-arrow__shaft {
        width: 0;
        background-color: $color-arrow;

        &:before,
        &:after {
          width: 0;
          background-color: $color-arrow;
        }
        &:before {
          transform: rotate(0);
        }

        &:after {
          transform: rotate(0);
        }
      }
    }
    &--right {
      //top: 3px;
      margin-left: $text-arrow-space;

      .animated-arrow__shaft {
        width: $shaft-width;
        transition-delay: 0.2s;

        &:before,
        &:after {
          width: $arrow-head-width;
          transition-delay: 0.3s;
          transition: all 0.5s;
        }
        &:before {
          transform: rotate(40deg);
        }

        &:after {
          transform: rotate(-40deg);
        }
      }
    }

    &__text {
      text-shadow: 5px 5px 30px rgba(255, 255, 255, .3)
    }
  }
  &__shaft {
    background-color: $color-arrow;
    display: block;
    height: $shaft-thickness;
    position: relative;
    transition: all 0.2s;
    transition-delay: 0;
    will-change: transform;

    &:before,
    &:after {
      background-color: $color-arrow;
      content: '';
      display: block;
      height: $arrow-head-thickness;
      position: absolute;
      top: 0;
      right: 0;
      transition: all 0.2s;
      transition-delay: 0;
    }

    &:before {
      transform-origin: top right;
    }

    &:after {
      transform-origin: bottom right;
    }
  }
  &__main {
    display: flex;
    align-items: center;
    transition: all 0.2s;
  }
  &__text {
    margin: 0 $text-arrow-space 0 0;
    line-height: 1;
  }

  &:hover, &:focus, &.hovered {
    color: $color-arrow-hover;
    text-decoration: none;

    .animated-arrow__arrow--left {
      .animated-arrow__shaft {
        width: $shaft-width;
        transition-delay: 0.1s;
        background-color: $color-arrow-hover;

        &:before,
        &:after {
          width: $arrow-head-width;
          transition-delay: 0.1s;
          background-color: $color-arrow-hover;
        }

        &:before {
          transform: rotate(40deg);
        }

        &:after {
          transform: rotate(-40deg);
        }
      }
    }

    .animated-arrow__main {
      transform: translateX($shaft-width + $text-arrow-space);

      .animated-arrow__arrow--right {
        .animated-arrow__shaft {
          width: 0;
          transform: translateX(200%);
          transition-delay: 0;

          &:before,
          &:after {
            width: 0;
            transition-delay: 0;
            transition: all 0.1s;
          }

          &:before {
            transform: rotate(0);
          }

          &:after {
            transform: rotate(0);
          }
        }
      }
    }


  }

}