.page {
  &_wrapper {
    position: relative;
    flex: 1 0 auto;

    &__inner {
      margin-top: 150px;
      margin-bottom: 50px;
    }
  }
  &_title {
    font-size: 42px;
    font-weight: 700;
    text-shadow: 5px 5px 30px rgba(255, 255, 255, 0.3);
    //text-align: center;
    margin-bottom: 20px;
  }
}