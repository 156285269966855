.email {
  &__tool {
    &:after {
      content: '/';
      display: flex;
      align-items: center;
      margin-left: 16px;
      font-size: 12px;
    }
    &.hide {
      display: none;
    }

    @include respond-to(b4-tablet) {
      display: none;
    }
    a {
      color: $qntm--color-white;

      &:focus, &:hover {
        text-decoration: none;
      }
    }
  }
}