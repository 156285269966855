.content_area {
  &__wrapper {
    margin-top: 180px;
    margin-bottom: 160px;

    @include respond-to(wide) {
      margin-top: 150px;
    }
    @include respond-to(mobile) {
      margin-top: 80px;
    }
  }
}