

.breadcrumbs {
  &__list {}
  &__item {
    display: inline-block;
    &:after {
      @extend %fa-icon;
      @extend .fal;

      content: fa-content($fa-var-chevron-right);
      margin-left: 5px;
      margin-right: 5px;

      font-size: 8px;
      vertical-align: middle;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  &__link {
    //padding: 5px 15px;

    color: $qntm--color-main-text;
    border-radius: 4px;

    &:hover, &:focus {
      color: $qntm--color-white;
      text-decoration: none;

      //background-color: $qntm--color-main-bg-secondary;
    }
  }
}