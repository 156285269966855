ol {
  list-style: none;
  counter-reset: my-awesome-counter;

  li {
    counter-increment: my-awesome-counter;
    margin: 0.25rem;
    &:before {
      content: counters(my-awesome-counter, ".") " ";
      border-top: 1px solid $qntm--color-main-border;
      border-right: 1px solid $qntm--color-main-border;
      background-color: darken($qntm--color-main-bg-secondary, 2%);

      font-size: 0.8em;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: inline-block;
      line-height: 2rem;
      color: $qntm--color-white;
      text-align: center;
      margin-right: 0.5rem;
    }

    ol {
      > li {
        margin-left: 30px;
      }
    }
  }
}